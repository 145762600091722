import http from '@/utils/http'
export default {
  taskinfo: (task, project) => {
    return http.get('taskinfo', {
      task,
      project
    })
  },
  finishtask: (id, explain, picture) => {
    return http.post('finishtask', {
      id,
      explain,
      picture
    })
  },
  projectinfo: (project) => {
    return http.get('projectinfo', {
      project
    })
  },
  tasklist: (project) => {
    return http.get('tasklist', {
      project
    })
  },
  preset: () => {
    return http.get('preset', {
    })
  },
  taskcount: () => {
    return http.get('taskcount', {})
  },
}
